import { Box, Button, Divider, Typography } from '@material-ui/core'
import AppsIcon from '@material-ui/icons/Apps'
import BookIcon from '@material-ui/icons/Book'
import GetAppIcon from '@material-ui/icons/GetApp'
import PolicyIcon from '@material-ui/icons/Policy'
import PublishIcon from '@material-ui/icons/Publish'
import PropTypes from 'prop-types'
import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { UserContext } from '../../contexts/userContext'
import logo from '../../logo.png'
import { USER_ROLES } from '../../utils/constants'
import './Sidebar.css'

SideBar.propTypes = {
  name: PropTypes.string.isRequired,
  canDisplayIndep: PropTypes.bool.isRequired,
}
SideBar.defaultProps = {
  name: '',
  canDisplayIndep: false,
}

export default function SideBar({ name, canDisplayIndep }) {
  const { userRole } = useContext(UserContext)
  return (
    <Box p={2} display={{ xs: 'none', sm: 'block' }} className="sidebar">
      <img style={{ width: '100%' }} src={logo} alt="Logo" />
      <Box>
        <Typography className="user-welcome">Bonjour {name}</Typography>
      </Box>
      <Box mt={2} mb={2}>
        <Typography variant="overline" color="textSecondary">
          Gestion
        </Typography>
        {canDisplayIndep && (
          <Typography>
            <Button size="small" component={Link} to="/" startIcon={<AppsIcon />}>
              Collecte indépendants
            </Button>
          </Typography>
        )}
        <Typography>
          <Button size="small" component={Link} to="/fb" startIcon={<AppsIcon />}>
            Collecte FB
          </Button>
        </Typography>
      </Box>
      <Divider variant="middle" />
      <Box mt={2} mb={2}>
        <Typography variant="overline" color="textSecondary">
          Import & Export
        </Typography>
        <Typography>
          <Button size="small" component={Link} to="/export" startIcon={<GetAppIcon />}>
            Exporter le fichier de collecte
          </Button>
        </Typography>
        {userRole === USER_ROLES.admin && (
          <Typography>
            <Button size="small" component={Link} to="/import" startIcon={<PublishIcon />}>
              Importer le fichier de collecte complété
            </Button>
          </Typography>
        )}
      </Box>
      <Divider variant="middle" />
      <Box mt={2} mb={2}>
        <Typography variant="overline" color="textSecondary">
          Politique de confidentialité
        </Typography>
        <Typography>
          <Button size="small" component={Link} to="/rgpd" startIcon={<PolicyIcon />}>
            Règles RGPD
          </Button>
        </Typography>
        <Typography>
          <Button
            size="small"
            href="https://heiway.sharepoint.com/sites/FR1-col-ArchivesADMHector/Archives%20Hector/Forms/AllItems.aspx"
            startIcon={<BookIcon />}
          >
            Accéder aux archives
          </Button>
        </Typography>
      </Box>
    </Box>
  )
}
